/* WESER-KURIER styles */

@import "../colors";
@import "../settings";

.kmta__tenant--weserkurier {
    @include breakpoint(medium) {
        .kmta__menu {
            margin-left: -29px;
        }
    }
    // #8629: Weserkurier: Logo springt beim Anmelden
    .kmta__headerTop {
        padding-top: 0px;
        padding-bottom: 0px;
        background-color: #ffffff;
    }
    &.is-guest .kmta__headerTop {
        padding-top: 0px;
        padding-bottom: 0px;
    }
    
    &.is-guest .kmta__headerBottom {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: -2px;
    }

    &.kmta__headerBottom {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-top: -2px;
    }

    .sticky{
        top: -2px!important;
    }

    &.is-guest .sticky {
        top: -0px!important;
    }
    a.weserkurier__logo {
        line-height: 0;
        
        .weserkurier_svgIcon--logo {
            height: 95px;
            width: 225px;
            margin-left: -28px;
        }
    }
    .kmta__loginRegisterLinks {
        padding-bottom: 10px;
        a {
            color: var(--anchor-color);
        }
    }

    .button.secondary {
        color: var(--primary-color);
    }

    .kmta__faqHome {
        background: linear-gradient(
            to bottom,
            rgba(236, 237, 237, 1) 0%,
            rgba(198, 199, 200, 1) 100%
        );
    }

    .kmta__editorDesignOption {
        width: 118px;
    }

    .dropdown.menu .is-active > a,
    .kmta__menu li.is-submenu-item a:hover {
        color: var(--nav-item-hover-color);
    }

    .dropdown.menu li.active > a,
    .menu.is-dropdown-submenu
        li.is-submenu-item.is-dropdown-submenu-item.active
        > a {
        font-weight: $global-weight-bold;
    }

    .kmta__contactperson.is-expanded {
        height: 242px;
        transition: height 0.25s ease-in;

        .is-guest & {
            height: 252px;
        }

        .kmta_svgIcon--chevron-arrow-down {
            display: block;
            cursor: pointer;
        }
        .kmta_svgIcon--chevron-arrow-up {
            display: none;
        }
    }

    .kmta__headerUserinfoWrapper{
        margin-top: 20px;
    }

    .kmta_svgIcon--login{
        color: var(--primary-color);
        fill:  var(--primary-color);
    }

    .login-link{
        color: var(--primary-color);
    }
}
